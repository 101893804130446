import { ref } from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'

export default function merchan() {
  const userForm = ref({
    user_id: '',
    merchant_type_id: '',
    name: '',
    logo: '',
    is_active: '',
    contract: '',
    client_cashback: '',
    zerocash_cashback: '',
  })

  const viewData = ref({})
  const { id } = store.state.generalIds
  const showUser = () => {
    if (id) {
      axios.get(`https://v2.zerocash.co/api/dashboard/v1/merchants/${id}`).then(res => {
        viewData.value = res.data?.data
      })
    }
  }
  showUser()

  const resolveActiveUser = role => {
    if (role === true) return 'Active'

    return 'Not Active'
  }

  return {
    userForm,
    viewData,
    resolveActiveUser,
  }
}
